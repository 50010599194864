import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AvatarService {
  private avatarSubject = new BehaviorSubject<string | null>(this.getSavedAvatarUrl());

  constructor() {}

  updateAvatarUrl(newAvatarUrl: string): void {
    console.log('Updating avatar URL:', newAvatarUrl);
    this.avatarSubject.next(newAvatarUrl);
    sessionStorage.setItem('avatarUrl', newAvatarUrl); // Save the new avatar URL in sessionStorage
  }

  getAvatarUrl(): Observable<string | null> {
    return this.avatarSubject.asObservable();
  }

  resetAvatarUrl(): void {
    console.log('Resetting avatar URL');
    this.avatarSubject.next(null);
    sessionStorage.removeItem('avatarUrl'); // Remove the avatar URL from sessionStorage
  }

  private getSavedAvatarUrl(): string | null {
    return sessionStorage.getItem('avatarUrl');
  }
}
