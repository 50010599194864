import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, BehaviorSubject, tap, finalize, catchError, of, map, throwError, retry } from "rxjs";
import { environment } from "src/environments/environment";
import { UserUpdateService } from "./user-update.service";
import { AvatarService } from "./avatar.service";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslationService } from "../../i18n/translation.service";


interface LoginResponse {
  message: string;
  userData: UserType;
}

export interface UserType {
  id: number;
  api_id: number;
  userId: number;
  name: string;
  surname: string;
  email: string;
  role: string;
  avatar: string | null;
  companyId: number;
  timezone: string;
  language: string;
}


@Injectable({
  providedIn: "root",
})
export class AuthService {
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(this.checkInitialAuthState());
  private apiUri = `${environment.apiUri}`;
  private userData: UserType | null = null;
  private userRole = new BehaviorSubject<string>("");
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  private currentUserSubject = new BehaviorSubject<UserType | null>(null);

  constructor(
    private http: HttpClient,
    private avatarService: AvatarService,
    private router: Router,
    private modalService: NgbModal,
    private userUpdateService: UserUpdateService,
    private translationService: TranslationService
  ) {
    this.initializeUserData();
  }

  get isLoading$(): Observable<boolean> {
    return this.isLoadingSubject.asObservable();
  }

  get currentUser$(): Observable<UserType | null> {
    return this.currentUserSubject.asObservable();
  }

  get currentUserValue(): UserType | null {
    return this.userData;
  }


  private initializeUserData(): void {
    const storedUserData = sessionStorage.getItem('userData');
    if (storedUserData) {
      const userData = JSON.parse(storedUserData) as UserType;
      this.setUserData(userData);
  
      // Apply the stored language immediately on page load
      if (userData.language) {
        this.translationService.setLanguage(userData.language);
      }
    } else {
      this.clearAuthenticationStatus();
    }
  }
  

  private clearAuthenticationStatus(): void {
    this.isAuthenticatedSubject.next(false);
    this.clearUserData();
  }

  private checkInitialAuthState(): boolean {
    return !!sessionStorage.getItem('userData');
  }

  private setUserData(data: UserType): void {
    this.userData = data;
    this.userRole.next(data.role);
    this.currentUserSubject.next(data);
    sessionStorage.setItem('userData', JSON.stringify(data));
  
    if (data.avatar) {
      this.avatarService.updateAvatarUrl(data.avatar);
    } else {
      this.avatarService.resetAvatarUrl();
    }
  
    this.updateAuthenticationStatus();
  }

  updateCurrentUser(data: Partial<UserType>): void {
    if (this.userData) {
      const updatedUserData = { ...this.userData, ...data };
      this.setUserData(updatedUserData);
    }
  }

  register(userData: any): Observable<any> {
    console.log('userData ',userData)
    this.isLoadingSubject.next(true);
    return this.http.post(`${this.apiUri}/auth/register`, userData).pipe(
      tap((response: any) => {
        if (response.userData) {
          this.setUserData(response.userData);
          this.updateAuthenticationStatus();
        }
      }),
      catchError((err) => {
        console.error('Registration error:', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  login(credentials: { email: string; password: string }): Observable<LoginResponse> {
    this.isLoadingSubject.next(true);
    return this.http
      .post<LoginResponse>(`${this.apiUri}/auth/login`, credentials, { withCredentials: true })
      .pipe(
        tap((response: LoginResponse) => {
          if (response.userData) {
            this.setUserData(response.userData);
            this.updateAuthenticationStatus();
            
            // Set the user's language immediately after login
            if (response.userData.language) {
              this.translationService.setLanguage(response.userData.language);
            }
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            console.error('Login failed: Invalid credentials.');
          } else {
            console.error('Login failed:', error);
          }
          return throwError(error);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }
  

  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<{ message: string }>(`${this.apiUri}/auth/forgot-password`, { email }).pipe(
      map(() => true),
      catchError((error) => {
        console.error('Forgot password error:', error);
        return of(false);
      })
    );
  }

  getUserByToken(): Observable<boolean> {
    // Only verify the token if we think the user is logged in
    if (!this.userData) {
      return of(false);
    }

    return this.http.get<{ message: string }>(`${this.apiUri}/auth/verify-token`, { withCredentials: true }).pipe(
      map(() => {
        // Token is valid
        this.updateAuthenticationStatus();
        return true;
      }),
      catchError((error) => {
        if (error.status === 401) {
          console.error('Token verification failed: User is not authenticated.');
          this.clearAuthenticationStatus();
        }
        return of(false);
      })
    );
  }

  getApiUserIdFromAuthService(): number | null {
    return this.userData ? this.userData.api_id : null;
  }

  getUserId(id: number): Observable<{ userId: string }> {
    return this.http.get<{ userId: string }>(`${this.apiUri}/user/getUserId/${id}`);
  }

  private updateAuthenticationStatus(): void {
    const isAuthenticated = !!this.userData;
    this.isAuthenticatedSubject.next(isAuthenticated);
  }

  getIsAuthenticated(): Observable<boolean> {
    return this.isAuthenticatedSubject.asObservable();
  }

  refreshToken(): Observable<void> {
    return this.http.post<void>(`${this.apiUri}/auth/refresh-token`, {}, { withCredentials: true }).pipe(
      tap(() => {
        console.log('Token refreshed');
      }),
      catchError((error) => {
        console.error('Failed to refresh token', error);
        return of(undefined);
      })
    );
  }

  changePassword(currentPassword: string, newPassword: string): Observable<any> {
    return this.http.put(`${this.apiUri}/auth/change-password`, { currentPassword, newPassword } , { withCredentials: true }).pipe(
      tap((response: any) => {
        console.log('Password change response:', response);
      }),
      catchError((error) => {
        console.error('Password change error:', error);
        return throwError(error);
      })
    );
  }
  

  logout(sessionExpired: boolean = false): void {
    this.http.post(`${this.apiUri}/auth/logout`, {}, { withCredentials: true })
      .pipe(
        retry(3), // Retry up to 3 times before failing
        finalize(() => {
          this.clearUserData();
          this.navigateAfterLogout(sessionExpired);
        }),
        catchError(error => {
          console.error('HTTP request failed during logout:', error);
          return of(null);
        })
      )
      .subscribe();
  }
  
  private navigateAfterLogout(sessionExpired: boolean): void {
    const queryParams = sessionExpired ? { sessionExpired: 'true' } : {};
    this.router.navigate(['/auth/login'], { queryParams });
  }
  

  hasRole(roles: string[]): boolean {
    return roles.includes(this.userData?.role || '');
  }

  private clearUserData(): void {
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('avatarUrl');
    localStorage.clear();
    sessionStorage.clear();
    this.userData = null;
    this.userRole.next("");
    this.currentUserSubject.next(null);
    this.avatarService.resetAvatarUrl();
    this.isAuthenticatedSubject.next(false);
  }
}
