import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslationService } from './modules/i18n';
import { ThemeModeService } from './_metronic/partials/layout/theme-mode-switcher/theme-mode.service';

@Component({
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    private translationService: TranslationService,
    private modeService: ThemeModeService
  ) {}

  ngOnInit() {
    // Load translations for the required languages from the backend
    this.translationService.loadTranslationsFromBackend('en');
    this.translationService.loadTranslationsFromBackend('pl');
    this.translationService.loadTranslationsFromBackend('de');

    this.modeService.init();
  }
}
