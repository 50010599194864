// import { Injectable } from '@angular/core';
// import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
// import { Observable, throwError } from 'rxjs';
// import { catchError } from 'rxjs/operators';
// import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
// import { AuthService } from 'src/app/modules/auth/services/auth.service';
// import { TokenExpiredModalComponent } from 'src/app/_metronic/layout/components/modals/token-expired-modal/token-expired-modal.component';

// @Injectable()
// export class AuthInterceptor implements HttpInterceptor {
//   private modalRef: NgbModalRef;

//   constructor(private authService: AuthService, private modalService: NgbModal) {}

//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     if (req.url.endsWith('/auth/login') || req.url.endsWith('/auth/register') || req.url.endsWith('/auth/refresh-token')) {
//       return next.handle(req);
//     }

//     return next.handle(req).pipe(
//       catchError((error: HttpErrorResponse) => {
//         if (error.status === 401) {
//           return this.handleAuthError(req, next);
//         }
//         return throwError(() => error);
//       })
//     );
//   }

//   private handleAuthError(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     this.modalRef = this.modalService.open(TokenExpiredModalComponent, {
//       backdrop: 'static',
//       keyboard: false
//     });
  
//     return new Observable(observer => {
//       const timeoutId = setTimeout(() => {
//         this.modalRef.close();
//         this.authService.logout(true);
//         observer.error('Session expired - logged out.');
//       }, 30000);
  
//       this.modalRef.result.then(
//         (result) => {
//           clearTimeout(timeoutId);
//           if (result === 'extend') {
//             this.authService.refreshToken().subscribe(
//               (newToken) => {
//                 const clonedReq = req.clone({
//                   setHeaders: {
//                     Authorization: `Bearer ${newToken}`
//                   }
//                 });
//                 next.handle(clonedReq).subscribe(observer);
//                 location.reload(); 
//               },
//               () => {
//                 this.modalRef.close(); 
//                 this.authService.logout(true);
//                 observer.error('Failed to refresh token.');
//               }
//             );
//           } else {
//             this.modalRef.close(); 
//             this.authService.logout(true);
//             observer.error('User chose to logout.');
//           }
//         },
//         () => {
//           clearTimeout(timeoutId);
//           this.modalRef.close(); 
//           this.authService.logout(true);
//           observer.error('Session expired - logged out.');
//         }
//       );
//     });
//   }
// }
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TokenExpiredModalComponent } from 'src/app/_metronic/layout/components/modals/token-expired-modal/token-expired-modal.component';
import { AuthService } from 'src/app/modules/auth/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private modalRef: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private injector: Injector  // Use Injector to avoid circular dependency
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.endsWith('/auth/login') || req.url.endsWith('/auth/register') || req.url.endsWith('/auth/refresh-token')) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return this.handleAuthError(req, next);
        }
        return throwError(() => error);
      })
    );
  }

  private handleAuthError(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.injector.get(AuthService);  // Lazy load AuthService here
    this.modalRef = this.modalService.open(TokenExpiredModalComponent, {
      backdrop: 'static',
      centered: true,
      keyboard: false
    });

    return new Observable(observer => {
      const timeoutId = setTimeout(() => {
        this.modalRef.close();
        authService.logout(true);
        observer.error('Session expired - logged out.');
      }, 30000);

      this.modalRef.result.then(
        (result) => {
          clearTimeout(timeoutId);
          if (result === 'extend') {
            authService.refreshToken().subscribe(
              (newToken) => {
                const clonedReq = req.clone({
                  setHeaders: {
                    Authorization: `Bearer ${newToken}`
                  }
                });
                next.handle(clonedReq).subscribe(observer);
                location.reload(); 
              },
              () => {
                this.modalRef.close(); 
                authService.logout(true);
                observer.error('Failed to refresh token.');
              }
            );
          } else {
            this.modalRef.close(); 
            authService.logout(true);
            observer.error('User chose to logout.');
          }
        },
        () => {
          clearTimeout(timeoutId);
          this.modalRef.close(); 
          authService.logout(true);
          observer.error('Session expired - logged out.');
        }
      );
    });
  }
}
