import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/modules/auth/services/auth.service';

@Component({
  selector: 'app-token-expired-modal',
  templateUrl: './token-expired-modal.component.html',
})
export class TokenExpiredModalComponent implements OnInit {
  countdown: number = 30;
  private intervalId: any;

  constructor(public activeModal: NgbActiveModal, private authService: AuthService) {}

  ngOnInit() {
    this.startCountdown();
  }

  startCountdown() {
    this.intervalId = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        this.close('cancel');
        clearInterval(this.intervalId);
      }
    }, 1000);
  }

  close(reason: string) {
    console.log('close reason ',reason)
    clearInterval(this.intervalId);
    this.activeModal.close(reason);
  }
}
